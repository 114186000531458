<template>
  <el-container class="container" ref="loadContainer">
    <el-main class="main-wrapper">
      <el-form label-width="160px" :model="submitData" :rules="submitRules" ref="createForm">
        <!-- 题目 -->
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionTopic')" prop="description">
          <el-input v-model="submitData.description"></el-input>
        </el-form-item>
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionTopicType')" prop="type">
          <el-select v-model="submitData.type" @change="questionTypeChange" style="width: 100%">
            <el-option v-for="typeItem in issueTypeList" :key="typeItem.value" :label="typeItem.label" :value="typeItem.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- 填空题类型 -->
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionFillType')" v-if="submitData.type === 'IN'" prop="inputtype">
          <el-select v-model="submitData.inputtype" style="width: 100%">
            <el-option v-for="typeItem in inputTypeList" :key="typeItem.value" :label="typeItem.label" :value="typeItem.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- 可选项 -->
        <el-form-item class="align-self-start" :label="$t('visitorQuestionTmpl.visitorQuestionOptional')" v-if="submitData.type === 'SC' || submitData.type === 'MC'" prop="options">
          <div class="issue_option_box" v-if="submitData.options.length">
            <div class="option_item" v-for="optionItem in submitData.options" :key="optionItem.seq" style="margin-bottom: 10px">
              <el-input v-model="optionItem.description" :class="{ 'is-conform': optionIsConform(optionItem) }">
                <el-button slot="append" @click="issueOptionDelete(optionItem)"><i class="el-icon-close"></i></el-button>
              </el-input>
            </div>
          </div>
          <!-- 添加一项 -->
          <el-button icon="el-icon-add-solid" @click="addIssueOption" v-if="!disabledAddOption">
            {{ $t('visitorQuestionTmpl.visitorQuestionAddOptional') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="closeDrawer">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="submitForm('createForm')">{{ $t('common.save') }}</el-button>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
