import VisitorQuestionTmplAddQuestionPage from '@/views/VisitorQuestionTmplAddQuestion/index.vue'
import VisitorQuestionTmplSelectQuestionPage from '@/views/VisitorQuestionTmplSelectQuestion/index.vue'

export default {
  name: 'VisitorQuestionTmplCreatePage',
  props: {
    editData: {
      type: Object,
      default: null,
    },
  },
  components: {
    VisitorQuestionTmplAddQuestionPage,
    VisitorQuestionTmplSelectQuestionPage,
  },
  data() {
    return {
      // 表单数据
      submitData: {
        description: '',
        name: '',
      },
      // 校验规则
      submitRules: {
        name: [
          { required: true, message: this.$t('visitorQuestionTmpl.visitorQuestionTmplNameTips'), trigger: 'blur' },
          { max: 200, message: this.$t('visitorQuestionTmpl.visitorQuestion200Tips'), trigger: 'blur' },
        ],
        description: [
          { required: true, message: this.$t('visitorQuestionTmpl.visitorQuestionTmplDescTips'), trigger: 'blur' },
          { max: 200, message: this.$t('visitorQuestionTmpl.visitorQuestion200Tips'), trigger: 'blur' },
        ],
      },
      paginationQuestionList: [], // 分页显示的问题列表
      // 问题分页配置
      issuePagination: {
        pageNum: 1, // 当前页码
        pages: 1, // 总页数
        pageSize: 5, // 每页显示条目个数
        pagerCount: 5, // 最大页码按钮数
        total: 0, // 条目总数
      },
      // 问题弹窗
      issueFormVisible: false,
      issueFormData: null,
      // 选择问题
      issueSelectVisible: false,
    }
  },
  computed: {
    // 问题类型列表
    issueTypeList() {
      return [
        {
          value: 'SC',
          label: this.$t('visitorQuestion.visitorQuestionTypeSc'),
        },
        {
          value: 'MC',
          label: this.$t('visitorQuestion.visitorQuestionTypeMc'),
        },
        {
          value: 'IN',
          label: this.$t('visitorQuestion.visitorQuestionTypeIn'),
        },
      ]
    },
  },
  created() {
    if (this.editData) {
      this.submitData = JSON.parse(JSON.stringify(this.editData))
      if (Array.isArray(this.submitData.questions)) {
        this.submitData.questions.forEach((item) => (item.id = this.$utils.generateUid()))
      }
      this.getPaginationQuestionList(1)
    }
  },
  methods: {
    // 显示问题表单弹窗
    showIssueForm() {
      this.issueFormVisible = true
      this.issueFormData = null
    },
    createIssueSuccess(data, isEdit) {
      if (isEdit) {
        let match = this.submitData.questions.find((item) => data.id === item.id)
        match && Object.assign(match, data)
      } else {
        if (Array.isArray(this.submitData.questions)) {
          this.submitData.questions.push(data)
        } else {
          this.submitData.questions = [data]
        }
      }
      this.getPaginationQuestionList(this.issuePagination.pageNum)
      this.issueFormVisible = false
    },
    closeIssueForm() {
      this.issueFormVisible = false
    },
    editClick(row) {
      this.issueFormData = {
        description: '',
        options: JSON.parse(JSON.stringify(row.question.body)),
        type: row.question.type,
        inputtype: row.question.inputtype || '',
        ...row,
      }
      if (row.question.description) {
        this.issueFormData.description = row.question.description
      }
      this.issueFormVisible = true
    },
    // 显示删除问题
    deleteClick(row) {
      this.$utils.blurFn()
      this.submitData.questions = this.submitData.questions.filter((iItem) => row.id !== iItem.id)
      this.getPaginationQuestionList(this.issuePagination.pageNum)
    },
    // 获取类型描述
    getTypeDesc(row) {
      let currentType = this.issueTypeList.find((iitem) => iitem.value === row.question.type)
      return currentType.label
    },
    // 获取表单的问题分页列表
    getPaginationQuestionList(pageNum) {
      if (pageNum) this.issuePagination.pageNum = pageNum
      this.submitData.questions.sort((a, b) => {
        return a.seq - b.seq
      })
      this.paginationQuestionList = this.setListPagination(this.submitData.questions, this.issuePagination)
    },
    /**
     * 设置分页
     * @param {Array} dataList 数据列表
     * @param {Object} pagination 分类配置
     * @returns 返回处理后的数据
     */
    setListPagination(dataList, pagination) {
      // 总数
      const total = dataList.length
      // 每页显示条数
      const pagesize = pagination.pageSize
      // 页数
      const pageCount = Math.ceil(dataList.length / pagesize) || 1
      // 设置分页配置
      pagination.total = total
      pagination.pages = pageCount
      if (pagination.pageNum > pagination.pages) {
        pagination.pageNum = pagination.pageNum - 1
        return this.setListPagination(dataList, pagination)
      }
      // 进行分页
      const resultList = dataList.slice((pagination.pageNum - 1) * pagesize, pagination.pageNum * pagesize)
      return resultList
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.editData) {
            // 修改数据
            const requsetEditJson = {
              hrid: this.$store.getters.loginid,
              penum: this.$store.getters.penum,
              tnum: this.submitData.tnum,
              name: this.submitData.name,
              description: this.submitData.description,
              questions: this.submitData.questions,
            }
            this.$http.post('/v2/question/template/edit', requsetEditJson).then((response) => {
              if (response.data.success) {
                this.$message({
                  type: 'success',
                  message: this.$t('visitorQuestionTmpl.editSuccess'),
                })
                this.$emit('createSuccess')
              } else {
                this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true,
                })
              }
            })
          } else {
            const requsetJson = {
              hrid: this.$store.getters.loginid,
              penum: this.$store.getters.penum,
              name: this.submitData.name,
              description: this.submitData.description,
              questions: this.submitData.questions || [],
            }
            this.$http.post('/v2/question/template/add', requsetJson).then((response) => {
              if (response.data.success) {
                this.$message({
                  type: 'success',
                  message: this.$t('visitorQuestionTmpl.createSuccess'),
                })
                this.$emit('createSuccess')
              } else {
                this.$alert(response.data.message, this.$t('common.alertPrompt'), {
                  type: 'error',
                  center: true,
                })
              }
            })
          }
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    // 关闭
    closeDrawer() {
      this.$emit('closeDrawer')
    },
    // 显示选择问题列表
    showQuestionList() {
      this.issueSelectVisible = true
      // this.getQuestionList(1)
    },
		comfirmIssueSelect(data) {
      if (!this.submitData.questions) this.submitData.questions = []
      // 当前最大的排序号
      let maxSeq = 0
      if (this.submitData.questions.length) {
        maxSeq = Math.max(...this.submitData.questions.map((iItem) => iItem.seq))
      }
      let qList = []
      // 设置数据结构
      data.forEach((cItem) => {
        cItem.seq = ++maxSeq
        cItem.id = this.$utils.generateUid()
        cItem.reusable = false
        qList.push({
          // 传qnum是问题不变，问题管理那边就不新增
          qnum: cItem.qnum,
          seq: cItem.seq,
          id: cItem.id,
          require: cItem.require,
          question: { ...cItem },
        })
      })
			this.submitData.questions = [...this.submitData.questions, ...qList]
			console.log('this.submitData.questions', this.submitData.questions)
			
      this.getPaginationQuestionList(this.issuePagination.pageNum)
      this.issueSelectVisible = false
    },
    closeIssueSelect() {
      this.issueSelectVisible = false
    },
  },
}
