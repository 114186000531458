export default {
  name: 'VisitorQuestionTmplAddQuestionPage',
  props: {
    editData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // 表单数据
      submitData: {
        seq: '', // 序号
        description: '', // 题目
        require: false, // 是否必填
        reusable: false, // 是否同步到问题管理，只有新增新增问题是才有
        type: '', // 可选项类型
        options: [], // 可选项
        inputtype: '', // 填空题的类型: 文本/数字/日期时间（TXT/NUM/DATE）
      },
      // 编辑状态
      editType: 'add',
      oldQuestionForm: null,
    }
  },
  created() {
    if (this.editData) {
      let submitData = JSON.parse(JSON.stringify(this.editData))
      this.editType = 'edit'
      this.submitData = {
        description: '',
        options: [],
        type: submitData.question.type,
        inputtype: submitData.question.inputtype || '',
        ...submitData,
      }
      this.oldQuestionForm = JSON.parse(JSON.stringify(this.submitData))
    }
  },
  computed: {
    // 问题表单校验
    issueFormRules() {
      const numRegExp = /^\d+$/
      const seqValidator = (rule, value, cb) => {
        if (value) {
          if (numRegExp.test(value)) {
            cb()
          } else {
            cb(new Error(this.$t('visitorQuestion.visitorQuestionNumTips')))
          }
        } else {
          cb(new Error(this.$t('visitorQuestion.visitorQuestionSeqTips')))
        }
      }
      const optionsValidator = (rule, value, cb) => {
        if (value.length) {
          // 有一项填了就行
          if (value.some((vItem) => vItem.description)) {
            // 是否小于200字
            let isAllLess200 = value.map((vItem) => vItem.description).every((dItem) => dItem.length <= 200)
            if (isAllLess200) {
              cb()
            } else {
              cb(new Error(this.$t('visitorQuestion.visitorQuestionOption200Tips')))
            }
          } else {
            cb(new Error(this.$t('visitorQuestion.visitorQuestionOptionalInputTips')))
          }
        } else {
          cb(new Error(this.$t('visitorQuestion.visitorQuestionOptionalTips')))
        }
      }
      return {
        seq: [{ required: true, validator: seqValidator, trigger: 'blur' }],
        description: [
          { required: true, message: this.$t('visitorQuestion.visitorQuestionTopicTips'), trigger: 'blur' },
          { max: 200, message: this.$t('visitorQuestionTmpl.visitorQuestion200Tips'), trigger: 'blur' },
        ],
        type: [{ required: true, message: this.$t('visitorQuestion.visitorQuestionTopicTypeTips'), trigger: 'change', max: 200 }],
        options: [{ required: true, validator: optionsValidator, trigger: 'change' }],
        inputtype: [{ required: this.submitData && this.submitData.type === 'IN', message: this.$t('visitorQuestion.visitorQuestionInputTypeTips'), trigger: 'change' }],
      }
    },
    // 问题类型列表
    issueTypeList() {
      return [
        {
          value: 'SC',
          label: this.$t('visitorQuestion.visitorQuestionTypeSc'),
        },
        {
          value: 'MC',
          label: this.$t('visitorQuestion.visitorQuestionTypeMc'),
        },
        {
          value: 'IN',
          label: this.$t('visitorQuestion.visitorQuestionTypeIn'),
        },
      ]
    },
    // 填空题的类型列表
    inputTypeList() {
      return [
        {
          value: 'TXT',
          label: this.$t('visitorQuestion.visitorQuestionInputtypeTxt'),
        },
        {
          value: 'NUM',
          label: this.$t('visitorQuestion.visitorQuestionInputtypeNum'),
        },
        {
          value: 'DATE',
          label: this.$t('visitorQuestion.visitorQuestionInputtypeDate'),
        },
      ]
    },
    // 禁用添加一项
    disabledAddOption() {
      return this.submitData.options.length >= 9
    },
  },
  methods: {
    // 问题类型变换
    questionTypeChange() {
      this.$nextTick(() => {
        this.$refs.createForm.clearValidate(['options', 'inputtype'])
      })
    },
    // 添加一项问题可选项
    addIssueOption() {
      let optionCount = this.submitData.options.length
      if (optionCount) {
        if (optionCount >= 9) return
        let max = Math.max(...this.submitData.options.map((iItem) => iItem.seq))
        this.submitData.options.push({ seq: max + 1, description: '' })
      } else {
        this.submitData.options.push({ seq: 1, description: '' })
      }
    },
    // 加一项 是否符合规则
    optionIsConform(optionItem) {
      return optionItem.description && optionItem.description.length <= 200
    },
    // 删除问题可选项
    issueOptionDelete(item) {
      this.submitData.options.splice(
        this.submitData.options.findIndex((iItem) => iItem.seq === item.seq),
        1
      )
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 根据问题类型对应清空操作
          if (this.submitData.type === 'IN') {
            this.submitData.options = []
          } else if (this.submitData.type === 'SC' || this.submitData.type === 'MC') {
            this.submitData.inputtype = ''
          }
          // 清空没有数据的可选项
          this.submitData.options = this.submitData.options.filter((oItem) => oItem.description)
          // 对比是否有不同，只需对比题目\题目类型\可选项\填空类型
          const isDiff = (obj1, obj2) => {
            for (const key in obj1) {
              if (key === 'description' || key === 'inputtype' || key === 'type') {
                if (obj1[key] !== obj2[key]) {
                  return true
                }
              }
              if (key === 'options') {
                if (obj1[key].length !== obj2[key].length) {
                  return true
                } else {
                  if (obj1[key].length > 0) {
                    for (let oIndex in obj1[key]) {
                      if (obj1[key][oIndex] !== obj2[key][oIndex]) {
                        return true
                      }
                    }
                  }
                }
              }
            }
          }
          // 编辑当前问题
          if (this.editType == 'edit') {
            let currentEditQuestionItem = {
              ...this.submitData,
            }
            if (isDiff(this.submitData, this.oldQuestionForm)) {
              currentEditQuestionItem.qnum = ''
              currentEditQuestionItem.question.qnum = ''
            }
            currentEditQuestionItem.seq = this.submitData.seq * 1
            currentEditQuestionItem.require = this.submitData.require
            currentEditQuestionItem.question.description = this.submitData.description
            currentEditQuestionItem.question.type = this.submitData.type
            currentEditQuestionItem.question.inputtype = this.submitData.inputtype
            currentEditQuestionItem.question.body = this.submitData.options
            currentEditQuestionItem.question.reusable = false
            this.$emit('createSuccess', currentEditQuestionItem, true)
          } else {
            // 模板的新增问题
            this.$emit('createSuccess', {
              id: this.$utils.generateUid(),
              seq: this.submitData.seq * 1,
              require: this.submitData.require,
              question: { body: this.submitData.options, ...this.submitData },
            })
          }
          // this.getPaginationQuestionList(1)
          // this.closeIssueForm()
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    // 关闭
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
