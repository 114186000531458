<template>
  <el-container class="container" ref="loadContainer">
    <el-main class="main-wrapper">
      <el-form label-width="auto" :model="submitData" :rules="submitRules" ref="createForm">
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionTmplName')" prop="name">
          <el-input v-model="submitData.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionTmplDesc')" prop="description">
          <el-input v-model="submitData.description"></el-input>
        </el-form-item>
        <el-form-item :label="$t('visitorQuestionTmpl.visitorQuestionList')">
          <div style="margin-bottom: 15px">
            <el-button type="primary" @click="showIssueForm" icon="el-icon-add-solid">{{ $t('visitorQuestionTmpl.visitorQuestionAdd') }}</el-button>
            <el-button type="primary" @click="showQuestionList">{{ $t('visitorQuestionTmpl.visitorQuestionSelect') }}</el-button>
          </div>
          <el-table ref="meterTable" :data="paginationQuestionList" style="width: 100%">
            <!-- 排序号 -->
            <el-table-column prop="seq" width="100" :label="$t('visitorQuestionTmpl.visitorQuestionSeq')"> </el-table-column>
            <!-- 题目 -->
            <el-table-column prop="description" :label="$t('visitorQuestionTmpl.visitorQuestionTopic')">
              <template slot-scope="scope">
                {{ scope.row.question.description }}
              </template>
            </el-table-column>
            <!-- 题目类型 -->
            <el-table-column :formatter="getTypeDesc" prop="type" width="120" :label="$t('visitorQuestionTmpl.visitorQuestionTopicType')"></el-table-column>
            <!-- 是否必填 -->
            <el-table-column prop="require" width="110" :label="$t('visitorQuestionTmpl.visitorQuestionRequired')">
              <template slot-scope="scope">
                {{ scope.row.require ? $t('common.yes') : $t('common.no') }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')" width="120">
              <template slot-scope="scope" v-if="$store.getters.isOperate && !$store.getters.isDemo">
                <el-tooltip effect="light" :content="$t('common.edit')" placement="top">
                  <el-button @click="editClick(scope.row, scope.$index)" type="text" icon="el-icon-edit"></el-button>
                </el-tooltip>
                <el-tooltip effect="light" :content="$t('common.delete')" placement="top">
                  <el-button @click="deleteClick(scope.row)" type="text" icon="el-icon-delete"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <pagination-box :total="issuePagination.total" :currentPage="issuePagination.pageNum" :pageSize="issuePagination.pageSize" @current-change="getPaginationQuestionList"></pagination-box>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-wrapper" height="70px">
      <el-button @click="closeDrawer">{{ $t('common.cancel') }}</el-button>
      <el-button type="primary" @click="submitForm('createForm')">{{ $t('common.save') }}</el-button>

      <!-- 新增问题 -->
      <el-dialog append-to-body :title="issueFormData ? $t('visitorQuestion.visitorQuestionEdit') : $t('visitorQuestion.visitorQuestionAdd')" :visible.sync="issueFormVisible" destroy-on-close width="650px">
        <visitor-question-tmpl-add-question-page v-if="issueFormVisible" :editData="issueFormData" @createSuccess="createIssueSuccess" @closeDialog="closeIssueForm"></visitor-question-tmpl-add-question-page>
      </el-dialog>

      <!-- 选择问题 -->
      <el-dialog append-to-body :title="$t('visitorQuestion.visitorQuestionBankTitle')" :visible.sync="issueSelectVisible" destroy-on-close width="750px">
        <visitor-question-tmpl-select-question-page :selectedData="submitData.questions" v-if="issueSelectVisible" @comfirmSelect="comfirmIssueSelect" @closeDialog="closeIssueSelect"></visitor-question-tmpl-select-question-page>
      </el-dialog>
    </el-footer>
  </el-container>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
